import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchSendActivation, fetchAuthenticateUser } from '../../actions/mailer';
import { getPath } from '../../config/helpers';
import {
  Button,
  Row,
  Col,
} from "reactstrap";

import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import Footer from "../../components/Footer/Footer.js";

function VerifyPage(props) {
  const [showBtn] = React.useState(false);
  const [isVerifyCallback] = React.useState(true);
  const [state, setState] = React.useState({showBtn,isVerifyCallback});

  React.useEffect(() => {
    let paths = getPath(window);
    if(paths[2] && paths[3]){
      props.fetchAuthenticateUser(paths[2],paths[3],(err,res)=>{
        if(err){
          console.log(err);
        }else{
          window.location = "/plan"
        }
      });
    }else{
      setState({...state,isVerifyCallback:false});
      props.fetchSendActivation(props.user.data.username,(err,res)=>{
        if(err){
          console.log(err);
        }
      });
      let oneSec = 1000;
      let oneMinute = oneSec * 60;
      let twoMinutes = oneMinute *2;
      setTimeout(()=>{
        setState({...state,showBtn:true});
      },twoMinutes)
      document.body.classList.toggle("landing-page");
      // Specify how to clean up after this effect:
      return function cleanup() {
        document.body.classList.toggle("landing-page");
      };
    }
    },[]);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <div className="page-header">
          {!state.isVerifyCallback?
          <>
          <img
            alt="..."
            className="path"
            src={require("../../assets/img/blob.png").default}
          />
          <img
            alt="..."
            className="path2"
            src={require("../../assets/img/path2.png").default}
          />
          <img
            alt="..."
            className="shapes triangle"
            src={require("../../assets/img/triunghiuri.png").default}
          />
          <img
            alt="..."
            className="shapes wave"
            src={require("../../assets/img/waves.png").default}
          />
          <img
            alt="..."
            className="shapes squares"
            src={require("../../assets/img/patrat.png").default}
          />
          <img
            alt="..."
            className="shapes circle"
            src={require("../../assets/img/cercuri.png").default}
          />
          </>
          :null}
          <div className="content-center">
            <Row className="row-grid justify-content-between align-items-center text-left">
              <Col lg="6" md="6">
                <h1 className="text-white">
                  We keep your account <br />
                  <span className="text-white">secured</span>
                </h1>
                
                <p className="text-white mb-3">
                  An email has been sent to you. Open to finish authenticating your account.
                </p>
                <div className="btn-wrapper mb-3">
                  {state.showBtn?
                  <>
                <p className="text-muted mb-3">
                  Didn't recieve an email?
                </p>
                <Button className="btn-simple" color="success" style={{color:'white'}}>
                Send Another Email <i className="tim-icons icon-send" />
                </Button>
                </>
                :null}
                  </div>
              </Col>
              <Col lg="4" md="5">
                <img
                  alt="..."
                  className="img-fluid"
                  src={require("../../assets/img/mail.svg").default}
                />
              </Col>
            </Row>
          </div>
        </div>
        <Footer />
      </div>
      </>
  );
}
VerifyPage.propTypes = {
  fetchSendActivation: PropTypes.func.isRequired,
  fetchAuthenticateUser: PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
  user: state.user,
  alert:state.alert
});
export default connect(mapStateToProps, { fetchSendActivation, fetchAuthenticateUser })(VerifyPage);