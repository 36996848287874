/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

export default function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row>
          {/* <Col>
            <h1 className="title" style={{marginBottom:'0px',fontSize:'1.5em'}}>Bangin' Empire <span style={{fontWeight:'300'}}>Podcast</span></h1>
          </Col> */}
          <Col>
            <Nav style={{paddingTop:'0px'}}>
              <NavItem>
                <NavLink href="https://shopbanginempire.com/#shop">
                  <span style={{fontSize:'1.5em'}}>Store</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://shopbanginempire.com/pages/contact">
                <span style={{fontSize:'1.5em'}}>Contact Us</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col>
            <h3 style={{fontSize:'1.5em',textAlign:'center'}}>Follow us:</h3>
            <div className="btn-wrapper profile" style={{fontSize:'1.5em',textAlign:'center'}}>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://www.youtube.com/channel/UCfihcdpd289C-VQROTFnUUw"
                id="tooltip622135962"
                target="_blank"
              >
                <i className="fab fa-youtube" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip622135962">
                Watch us
              </UncontrolledTooltip>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://www.facebook.com/hanginwbangin"
                id="tooltip230450801"
                target="_blank"
              >
                <i className="fab fa-facebook-square" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip230450801">
                Like us
              </UncontrolledTooltip>
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://www.instagram.com/sherbybangin69/"
                id="tooltip318450378"
                target="_blank"
              >
                <i className="fab fa-instagram" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip318450378">
                Follow us
              </UncontrolledTooltip>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
