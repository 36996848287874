import React from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    ListGroupItem,
    CardText,
    ListGroup,
    Form,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    CardImg,
    CardImgOverlay,
  } from "reactstrap";
  

export default function Post(props) {
    // style={{ background: "linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.3)),url('"+props.data.imgURL+"')" }
    return (
        <Row>
            <Col xs={{size:12,offset:0}} md={{size:8,offset:2}}>
                <Card style={{height:'auto'}}>
                    <CardImg className="dark-blur" style={{opacity:'.9'}} src={props.data.imgURL}/>
                    <CardBody style={{ padding: '60px', paddingLeft: '30px', paddingRight: '30px',position:'absolute',height:'-webkit-fill-available'}}>
                        <CardTitle>
                            <h2 style={{ color: '#fff' }}>{props.data.name}</h2>
                        </CardTitle>
                        <CardText>
                            <div className="none-md" style={{ height: '100px', width: '60%',overflowY:'hidden'}}>
                                {props.data.description}
                            </div>
                        </CardText>
                        {props.data.type=="audio"?
                        <a href={"/post/"+props.data._id}className="btn btn-success margin-md-0 margin-top-md-3" style={{marginTop:'20px'}}>Listen now</a>
                        :
                        <a href={"/post/"+props.data._id}className="btn btn-info margin-md-0 margin-top-md-3" style={{marginTop:'20px'}}>Watch now</a>}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}