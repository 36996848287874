import { FETCH_LOGIN, UPDATE_USER, FETCH_REGISTER, FETCH_RESET_PASSWORD } from './types';
import { API_URI, CLIENT_URL } from '../config/variables';
import { createMessage } from '../actions/alert';
import { FETCH, isCallback } from '../config/helpers'
import jwt_decode from "jwt-decode";
import { toggleLoading } from './loading';

export const fetchLogin = (email, password, callback) => dispatch => {

    let body = { email: email, password: password}

    FETCH('POST', '/customer/login', body, null, dispatch, true, function (err, res) {
        if (err) {
            dispatch(createMessage(err, 'danger'));
            isCallback(err, true, callback);
        } else {
            let jwt = res;
            let user = jwt_decode(jwt);
            localStorage.setItem('jwt', jwt);
            dispatch({
                type: FETCH_LOGIN,
                payload: user
            });
            isCallback(user, false, callback);
        }
    });

}
export const updateUser = (jwt) => dispatch => {
    let user;
    let error = false;
    try {
        user = jwt_decode(jwt);
    } catch (err) {
        error = true
    }
    if (error) {
        dispatch({
            type: UPDATE_USER,
            payload: null
        });
        dispatch(toggleLoading(false));
        dispatch(createMessage("Error decoding jwt",'danger'));
    } else {
        dispatch({
            type: UPDATE_USER,
            payload: user
        });
    }
}
export const fetchRegister = (email, password, name, callback) => dispatch => {
    let body = { email: email, password: password, name: name }

    FETCH('POST', '/customer/register', body, null, dispatch, true, function (err, res) {
        if (err) {
            dispatch(createMessage(err, 'danger'));
            isCallback(err, true, callback);
        } else {
            let jwt = res;
            let user = jwt_decode(jwt);
            localStorage.setItem('jwt', jwt);
            dispatch({
                type: FETCH_REGISTER,
                payload: user
            });
            isCallback(user, false, callback);
        }
    });
}
export const fetchResetPassword = (email,password,token,callback) => (dispatch) => {
    let body = {
        email,
        password,
        token
    }

    FETCH('POST','/customer/reset-password',body,null,dispatch,true,function(err,res){
      if(err){
        dispatch(createMessage(err, 'danger'));
        isCallback(err, true, callback);
      }else{
            let jwt = res;
            let user = jwt_decode(jwt);
            localStorage.setItem('jwt', jwt);
            dispatch({
                type: FETCH_REGISTER,
                payload: user
            });
            isCallback(user, false, callback);
      }
    });
  }

//   export const fetchIsAuth = () =>{
//     FETCH('POST','/customer/is-auth',body,null,dispatch,true,function(err,res){
//         if(err){
//           dispatch(createMessage(err, 'danger'));
//           isCallback(err, true, callback);
//         }else{
//               let jwt = res;
//               let user = jwt_decode(jwt);
//               localStorage.setItem('jwt', jwt);
//               dispatch({
//                   type: FETCH_REGISTER,
//                   payload: user
//               });
//               isCallback(user, false, callback);
//         }
//       });
//   }