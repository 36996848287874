import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {fetchGetAllPostsViaUser} from '../../actions/media';
import {
  Container,
  Row,
  Col,
} from "reactstrap";

import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import Footer from "../../components/Footer/Footer.js";
import Post from '../../components/Home/Post';

function HomePage(props) {
  const [posts] = React.useState([]);
  const [state, setState] = React.useState({posts});

  React.useEffect(() => {
    props.fetchGetAllPostsViaUser((err,posts)=>{
      if(err){
        console.log(err);
      }else{
        setState({...state,posts:posts})
      }
    });

    document.body.classList.toggle("landing-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("landing-page");
    };
  },[]);

  const list = () =>{
    return state.posts.map((post,index)=>{
      return(
        <Post id={"post"+index} data={post}/>
      );
    });
  }
  return (

    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <div style={{marginTop:'6em'}}>
          <Container>
            <Row>
              <Col xs={12}>
                <h6 class="category text-muted text-centered">Our work</h6>
                <h1 style={{textAlign:'center'}}>Our Latest Episodes</h1>
              </Col>
            </Row>
            {state.posts.length>0?
            list()
            :null}
          </Container>
        <Footer />
      </div>
      </div>
    </>
  );
}
HomePage.propTypes = {
  fetchGetAllPostsViaUser: PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
  user: state.user,
  alert:state.alert
});
export default connect(mapStateToProps, {fetchGetAllPostsViaUser })(HomePage);
