import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import {Provider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
} from '@stripe/react-stripe-js';
import { STRIPE_CLIENT_KEY } from './config/variables';

const stripePromise = loadStripe(STRIPE_CLIENT_KEY);

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
    </PersistGate>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);
