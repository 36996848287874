import { FETCH, isCallback } from '../config/helpers';
import { createMessage } from '../actions/alert';

export const fetchGetAll = (callback) => dispatch => {

    FETCH('POST','/plan/get-all',null,localStorage.getItem('jwt'),dispatch,true,function(err,res){
        if (err) {
            dispatch(createMessage(err, 'danger'));
            isCallback(err, true, callback);
        } else {
            isCallback(res, false, callback);
        }
    })

}