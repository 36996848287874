import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {formatPrice,getPath} from '../../config/helpers';
import {fetchGetAll} from '../../actions/plan'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import Footer from "../../components/Footer/Footer.js"
import Plan from "../../components/Plan/Plan";

function PlanPage(props) {
  const [plans] = React.useState([]);
  const [state, setState] = React.useState({plans});

  React.useEffect(() => {
    props.fetchGetAll((err, res) => {
      if (err) {
        console.log(err);
      } else {
        setState({...state,plans:res});
        console.log(res);
      }
    });
    document.body.classList.toggle("landing-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("landing-page");
    };
  },[]);
  const list = () =>{
    return state.plans.map((plan,index)=>{
      plan.list = plan.description.split(".");
      return(
        <Plan id={"plan"+index} data={plan}/>
      );
    });
  }
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <div style={{marginTop:'5em'}}>
          <img
            alt="..."
            className="path"
            src={require("../../assets/img/path4.png").default}
          />
          <Container>
            <Row style={{marginBottom:'6em'}}>
              <Col md="4">
                <hr className="line-info" />
                <h1>
                Get ready{" "}
                  <span className="text-info">for the time of your life!</span>
                </h1>
              </Col>
            </Row>
            <Row>
              {state.plans.length>0?
              <>
              {list()}
              </>
              // <Col lg={{size:4,offset:4}}>
              //   <Card className="card-coin card-plain">
              //     <CardHeader>
              //       <i className="tim-icons icon-headphones" style={{fontSize:'7em',color:'#1d8cf8'}}></i>
              //     </CardHeader>
              //     <CardBody>
              //       <Row>
              //         <Col className="text-center" md="12">
              //           <h4 className="text-uppercase"> ${formatPrice(state.plans[0].price)}/month</h4>
              //           <span>Plan</span>
              //           <hr className="line-info" />
              //         </Col>
              //       </Row>
              //       <Row>
              //         <ListGroup>
              //           <ListGroupItem>Exclusive access</ListGroupItem>
              //           <ListGroupItem>Full video</ListGroupItem>
              //           <ListGroupItem>Tons of giveaways</ListGroupItem>
              //           <ListGroupItem>Interact with Bangin’</ListGroupItem>
              //           <ListGroupItem>Live comment section</ListGroupItem>
              //         </ListGroup>
              //       </Row>
              //     </CardBody>
              //     <CardFooter className="text-center">
              //       <a href={"/checkout-page/"+state.plans[0]._id} className="btn btn-info btn-simple" color="info">
              //         Get plan
              //       </a>
              //     </CardFooter>
              //   </Card>
              // </Col>
              :null}
            </Row>
          </Container>
        <Footer />
      </div>
      </div>
    </>
  );
}
PlanPage.propTypes = {
  fetchGetAll: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  fetchCheckoutSession: PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
  user: state.user,
  alert:state.alert
});
export default connect(mapStateToProps, { fetchGetAll })(PlanPage);