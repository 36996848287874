
import React from "react";
import {
  textValidator,
  mp4Validator,
  imageValidator,
} from "../../config/validators";
import {
  fetchCreateURL,
  uploadToAws,
  fetchCreateMedia,
} from "../../actions/media";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Form,
  Input,
  Label,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  FormGroup,
  Col,
} from "reactstrap";

export default class AddMedia extends React.Component {
  constructor(props) {
    super(props);
    this._initState();
  }
  _initObj = () => {
    return({
      awsBuckets: [],

      name: "",
      description: "",
      type: "video",
      image: "",
      file: "",

      nameErrMsg: "",
      descriptionErrMsg: "",
      imageErrMsg: "",
      fileErrMsg: "",
    })
  };
  _initState = () => {
    if (!this.state) {
      this.state = this._initObj();
    } else {
      this.setState(this._initObj());
    }
  };
  _setErrorMsg = (txt) => {
    alert("Error: " + txt.toString());
  };

  _onChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };
  _validation = () => {
    let newState = this.state;
    newState.nameErrMsg = textValidator(this.state.name, true).errorMsg;
    newState.descriptionErrMsg = textValidator(
      this.state.description,
      true
    ).errorMsg;
    newState.imageErrMsg = imageValidator(this.state.image, true).errorMsg;
    newState.fileErrMsg = mp4Validator(this.state.file, true).errorMsg;
    this.setState(newState);
  };
  _submit = async (e) => {
    e.preventDefault();

    this._validation();
    if (
      this.state.nameErrMsg.length < 1 &&
      this.state.descriptionErrMsg.length < 1 &&
      this.state.imageErrMsg.length < 1 &&
      this.state.fileErrMsg.length < 1
    ) {
      let imageFile = document.getElementsByName("image")[0].files[0];
      let mediaFile = document.getElementsByName("file")[0].files[0];

      uploadToAws(imageFile, this.state.awsBuckets[0].url, (err, res) => {
        if (err) {
          this._setErrorMsg(err);
        } else {
          uploadToAws(mediaFile, this.state.awsBuckets[1].url, (err, res) => {
            if (err) {
              this._setErrorMsg(err);
            } else {
              fetchCreateMedia(
                this.state.name,
                this.state.description,
                this.state.awsBuckets[0].key,
                this.state.awsBuckets[1].key,
                this.state.type,
                (err, res) => {
                  if (err) {
                    this._setErrorMsg(err);
                  } else {
                    alert("Successfully Added media");
                    this._initState();
                    this._getawsURLs();
                  }
                }
              );
            }
          });
        }
      });
    }
  };
  _getawsURLs = () =>{
    fetchCreateURL((err, res1) => {
      if (err) {
        this._setErrorMsg(err);
      } else {
        let newState = this.state;
        fetchCreateURL((err, res2) => {
          if (err) {
            this._setErrorMsg(err);
          } else {
            newState.awsBuckets.push({
              key: res1.key,
              url: res1.url,
            });
            newState.awsBuckets.push({
              key: res2.key,
              url: res2.url,
            });
            this.setState(newState);
          }
        });
      }
    });
  }
  componentDidMount = () => {
    this._getawsURLs();
  };

  render() {
    return (
      <div className="row">
        {this.state && this.state.awsBuckets.length > 1 ? (
          <>
            <div className="col-lg-6">
              <div className="form-group" style={{ marginBottom: "30px" }}>
                <label className="label">Media Name</label>
                <div className="input-group">
                  <Input
                    type="text"
                    className="form-control admin-input"
                    name="name"
                    placeholder="Media Name"
                    value={this.state.name}
                    onChange={(e) => {
                      this._onChange(e);
                    }}
                  />
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {this.state.nameErrMsg}&nbsp;
                  </div>
                </div>
              </div>
              <div className="form-group" style={{ marginBottom: "30px" }}>
                <label className="label">Media File</label>
                <div className="input-group">
                  <input
                    type="file"
                    className="form-control admin-input"
                    name="file"
                    placeholder="File.mp4"
                    value={this.state.file}
                    onChange={(e) => {
                      this._onChange(e);
                    }}
                  />
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {this.state.fileErrMsg}&nbsp;
                  </div>
                </div>
              </div>
              <div className="form-group" style={{ marginBottom: "30px" }}>
                <label className="label">Default Image</label>
                <div className="input-group">
                  <Input
                    type="file"
                    className="form-control admin-input"
                    name="image"
                    placeholder="Default Image"
                    value={this.state.image}
                    onChange={(e) => {
                      this._onChange(e);
                    }}
                  />
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {this.state.imageErrMsg}&nbsp;
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label for="exampleFormControlSelect2">Select Media Type</label>
                <Input
                  onChange={(e) => {
                    this._onChange(e);
                  }}
                  type="select"
                  name="type"
                  className="form-control admin-input"
                  id="exampleFormControlSelect2"
                >
                  <option selected value="video">
                    video
                  </option>
                  <option value="audio">audio</option>
                </Input>
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {this.state.typeErrMsg}&nbsp;
                </div>
              </div>

              <div className="form-group">
                <label for="exampleFormControlTextarea1">Description</label>
                <Input
                  type="textarea"
                  onChange={(e) => {
                    this._onChange(e);
                  }}
                  name="description"
                  className="form-control admin-input"
                  id="exampleFormControlTextarea1"
                  rows="9"
                  value={this.state.description}
                ></Input>
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {this.state.descriptionErrMsg}&nbsp;
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <button
                  className="btn btn-primary submit-btn btn-block"
                  onClick={this._submit}
                >
                  Upload
                </button>
              </div>
            </div>
          </>
        ) : null}
      </div>
    );
  }
}