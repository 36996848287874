import { TOGGLE_LOADING } from './types';

// CREATE MESSAGE
export const toggleLoading = (value) => {
  if(value){
    let dom = document.getElementsByClassName('wrapper')[0];
    dom.classList.add('dark-blur')
    setTimeout(function(){
      let dom = document.getElementsByClassName('wrapper')[0];
      dom.classList.remove('dark-blur')
    },20000);
  }else{
    let dom = document.getElementsByClassName('wrapper')[0];
    dom.classList.remove('dark-blur')
  }
  return {
    type: TOGGLE_LOADING,
    payload: value,
  };
};