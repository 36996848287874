import React from 'react';
import {
    Alert
  } from "reactstrap";

export default function AlertBanner(props){
        const _closeAlert = () =>{
            props.createMessage("","",null);
        }
        return(
            <Alert isOpen={props.data.text.length>0}
            toggle={_closeAlert} color={props.data.alertType}>{props.data.text}&nbsp;</Alert>
        )
    
}