import {CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import React from "react";

const StripeForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmCardPayment(props.CLIENT_SECRET, {
      payment_method: {
        card: elements.getElement(CardElement),
      }
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      props.failMsg(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        props.successMsg("You have successfully subscribed.");
        // post-payment actions.
        stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
        }
      ).then(function(result) {
        if (result.error) {
          // Display error.message in your UI
          // resultContainer.textContent = result.error.message;
          props.failMsg(result.error.message);
        } else {
          // You have successfully created a new PaymentMethod
          // resultContainer.textContent = "Created payment method: " + result.paymentMethod.id;
          props.createCustomer(result.paymentMethod.id);
        }
      });
        // props.toggleSuccessMsg();
      }
    }
  };
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        // fontSize: "25px",
        color: "#FFFFFF",
        backgroundColor: "inherit",
        borderRadius: "inherit",
        // height:'300px!important',
        // flex:'1'
      },
      invalid: {
        color: "inherit",
      },
    },
  };

  return (
      <form onSubmit={handleSubmit}>
        <CardElement options={CARD_ELEMENT_OPTIONS}/>
          {/* <h4>Total: ${props.total.toFixed(2)}</h4> */}
          <button className="btn btn-info" style={{width:'100%',marginTop:'30px'}} type="submit" disabled={!stripe}>
              Pay ${props.total}
          </button>
      </form>
  );
};

export default StripeForm;