import React from 'react';
import { formatPrice } from '../../config/helpers';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    ListGroupItem,
    ListGroup,
    Container,
    Row,
    Col,
  } from "reactstrap";

export default function Plan(props){
    const list = () =>{
        return props.data.list.map((item,index)=>{
          return(
            <ListGroupItem id={"listItem"+index}>{item}</ListGroupItem>
          );
        });
      }
    return(
        <Col lg={{size:4}} className="mx-auto">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <i className="tim-icons icon-headphones" style={{fontSize:'7em',color:'#1d8cf8'}}></i>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col className="text-center" md="12">
                        <h4 className="text-uppercase"> ${formatPrice(props.data.price)}/{props.data.interval}</h4>
                        <span>{props.data.name}</span>
                        <hr className="line-info" />
                      </Col>
                    </Row>
                    <Row>
                      <ListGroup>
                        {list()}
                        {/* <ListGroupItem>Exclusive access</ListGroupItem>
                        <ListGroupItem>Full video</ListGroupItem>
                        <ListGroupItem>Tons of giveaways</ListGroupItem>
                        <ListGroupItem>Interact with Bangin’</ListGroupItem>
                        <ListGroupItem>Live comment section</ListGroupItem> */}
                      </ListGroup>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-center">
                    <a href={"/checkout-page/"+props.data._id} className="btn btn-info btn-simple" color="info">
                      Get plan
                    </a>
                  </CardFooter>
                </Card>
              </Col>
    )
}