import React from 'react';
import {formateDateAndTime} from '../../config/helpers';
import {
    Row,
    Col
  } from "reactstrap";
  

export default function Comment(props) {
   
    return (
            <Row>
                <Col xs="2">
                <i className="tim-icons icon-single-02" style={{ fontSize: '1.5em', paddingTop: '.5em', color: '#1d8cf8' }} />
                </Col>
                <Col xs="10" style={{ paddingLeft: "0px" }}>
                <Row>
                    <Col>
                    <span style={{ fontSize: "0.75em",float:'left' }}>{props.data.customerName}</span>
                    </Col>
                    <Col>
                    <span style={{ fontSize: "0.75em", float: "right" }}>{formateDateAndTime(props.data.dateCreated)}</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <p style={{ fontSize: "0.8em" }}>
                    {props.data.message}
                </p>
                    </Col>
                </Row>
                </Col>
            </Row>              
    )
}