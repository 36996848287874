import { FETCH_POSTS } from '../actions/types'
const initialState = {
    data:[],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_POSTS:
      return(state = action.payload);
    default:
      return state;
  }
}