import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchGetBillingSession } from "../../actions/payment";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import Footer from "../../components/Footer/Footer.js";

class AccountView extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
  };
  state = {
    accountURL: "",
  };
  constructor(props) {
    super(props);
  }
  _setErrorMsg = (txt) => {
    alert("Error: " + txt.toString());
  };
  componentDidMount = () => {
    fetchGetBillingSession((err, result) => {
      if (err) {
        this._setErrorMsg(err);
      } else {
        this.setState({ ...this.state, accountURL: result.url });
      }
    });
  };
  render() {
    return (
      <>
      <ExamplesNavbar />
        <div className="wrapper">
          <div style={{ marginTop: '7em' }}>
            <img
              alt="..."
              className="path"
              src={require("../../assets/img/path4.png").default}
            />
            <Container style={{ marginBottom: '1em' }}>
        <h4 style={{ textAlign: "center"}}>Account</h4>
        <div className="card" style={{ padding: "30px", padding: "10%" }}>
          <div className="row">
            <div className="col-12">
              <div class="form-group">
                <label for="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  disabled
                  style={{color:'#fff'}}
                  value={this.props.user.data.username}
                />
                <small id="emailHelp" class="form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
              </div>
            </div>
          </div>
          {this.state.accountURL.length>0?
          <div className="row">
            <div className="col-12">
              <a className="btn btn-info" style={{width:'100%'}} href={this.state.accountURL}>View Subscription</a>
            </div>
          </div>
          :null}
        </div>
        </Container>
            <Footer />
          </div>
        </div>
        </>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {})(AccountView);
