import { FETCH_LOGIN, UPDATE_USER, FETCH_REGISTER, FETCH_RESET_PASSWORD} from '../actions/types';

const initialState = {
    data:null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_LOGIN:
      return {
        ...state,
        data: action.payload
      };
    case  FETCH_REGISTER:
      return {
        ...state,
        data: action.payload
      };
    case UPDATE_USER:
      return {
        ...state,
        data: action.payload
      };
    case FETCH_RESET_PASSWORD:
      return{
          ...state,
          data:action.payload
      }
    default:
      return state;
  }
}