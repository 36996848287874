import { API_URI, CLIENT_URL } from '../config/variables';
import { FETCH, isCallback } from '../config/helpers';
import { FETCH_POSTS} from './types';
import { createMessage } from '../actions/alert';

export function fetchCreateURL(callback) {
    const bearer = "Bearer " + localStorage.getItem("jwt");
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: bearer,
        }
    };

    fetch(API_URI + "/media/aws/get-url/create", requestOptions)
        .then(res => {
            if(res.status == 401){
                window.location = "login";
                // return {error:"Access denied please login."};
            }else if(res.status>=400){
                return {error:"Bad request"};
            }else{
                return res.json();
            }
        })
        .then(data => {
            if (data.error) {
                callback(data.error);
            } else {
                callback(null, data.result)
            }
        });

}

export function fetchGetAllPosts(callback) {
    const bearer = "Bearer " + localStorage.getItem("jwt");
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: bearer,
        }
    };

    fetch(API_URI + "/media/aws/get-urls/posts", requestOptions)
        .then(res => {
            if(res.status == 401){
                window.location = CLIENT_URL + "/login"
                // return {error:"Access denied please login."};
            }else if(res.status>=400){
                return {error:"Bad request"};
            }else{
                return res.json();
            }
        })
        .then(data => {
            if (data.error) {
                callback(data.error);
            } else {
                callback(null, data.result)
            }
        });

}

export const addView = (mediaID,callback) => dispatch => {
    FETCH('POST','/media/add-view/'+mediaID,null,localStorage.getItem('jwt'),dispatch,false,function(err,res){
        if (err) {
            dispatch(createMessage(err, 'danger'));
            isCallback(err, true, callback);
        } else {
            isCallback(res, false, callback);
        }
    })

}

export const fetchGetAllPostsViaUser = (callback) => dispatch => {

    FETCH('POST', '/media/aws/get-content/users', null, localStorage.getItem('jwt'), dispatch, true, function (err, res) {
        if (err) {
            dispatch(createMessage(err, 'danger'));
            isCallback(err, true, callback);
        } else {
            dispatch({
                type: FETCH_POSTS,
                payload: res
            });
            isCallback(res, false, callback);
        }
    });

}

export function fetchCreateMedia(name, description, awsImageKey, awsMediaKey, type, callback) {
    const bearer = "Bearer " + localStorage.getItem("jwt");
    let body = {
        name,
        description,
        type: type,
        description,
        refID: awsMediaKey,
        imgRefID: awsImageKey
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: bearer,
        },
        body: JSON.stringify(body)
    };

    fetch(API_URI + "/media/create", requestOptions)
        .then(res => {
            if (res.status >= 400) {
                console.log("err");
            }
            return res.json();
        })
        .then(data => {
            if (data.error) {
                callback(data.error);
            } else {
                callback(null, data.result)
            }
        });

}

export function uploadToAws(file, awsURL, callback) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'content-type': 'application/octet-stream'
        },
        body: file
    };

    fetch(awsURL, requestOptions)
        .then(res => {
            if (res.status >= 400) {
                console.log("err");
                callback("error")
            } else {
                callback(null, res);
            }

        });
}
