import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchResetPassword } from "../../actions/user";
import { getPath } from '../../config/helpers'
import { passwordValidator } from '../../config/validators';
import { createMessage } from "../../actions/alert"
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormText,
  CardImg,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  FormGroup,
  Col,
} from "reactstrap";

import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import Footer from "../../components/Footer/Footer.js";

class ResetPasswordPage extends React.Component{
  constructor(props){
    super(props);
    this._updateFormData = this._updateFormData.bind(this);
    this._submit = this._submit.bind(this);
    let paths = getPath(window);
    this.state = {
      email:paths[2],
      token:paths[3],
      password:"",
      passwordErrMsg:""
    }
  }

  _updateFormData = (e) =>{
    this.setState({ [e.target.name]: e.target.value });
  }
  _validation = () =>{
    let newState = this.state;
    newState.passwordErrMsg = passwordValidator(this.state.password,true).errorMsg;
    this.setState(newState);
  }
  _submit = (e) =>{
    e.preventDefault();
    
    this._validation();
    if(this.state.passwordErrMsg.length<1){
      this.props.fetchResetPassword(this.state.email,this.state.password,this.state.token,(err,res)=>{
        if(err){
          console.log(err);
        }else{
          window.location = "/login-page"
        }
      });
    }else{
      let msg = this.state.passwordErrMsg;
      this.props.createMessage(msg,'danger',1000000);
    }
  }
  _renderForm =() =>{
    return (
      <>
      <ExamplesNavbar />
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content" style={{marginTop:'14em'}}>
            <Container>
              <Row>
                <Col lg={{size:6,offset:3}}>
      <Card>
      <CardBody>
        <form style={{textAlign:'left',padding:'1em'}}>
        <h2>Reset Password</h2>
          <FormGroup>
            <Label for="examplePassword">New Password</Label>
            <Input
              type="password"
              name="password"
              onChange={this._updateFormData}
              id="examplePassword"
              placeholder="Password"
              autoComplete="off"
            />
          </FormGroup>
          <Button color="primary" type="submit" onClick={this._submit}>
            Submit
          </Button>
        </form>
      </CardBody>
    </Card>
    </Col>
    </Row>
    </Container>
    </div>
        </div>
        <Footer />
      </div>
      </>
    );
  }
  render(){
    return(
    <>
      {this._renderForm()}
    </>
    );
  }
}

export default connect(null,{fetchResetPassword,createMessage})(ResetPasswordPage);