import { API_URI } from '../config/variables';
import { updateUser } from './user';
import { toggleLoading } from './loading';
import { createMessage } from '../actions/alert';
import { FETCH, isCallback } from '../config/helpers'

export const fetchIntent = (planID,callback) => dispatch => {
    let body = {planID};
    FETCH('POST', '/payment/send-intent', body, localStorage.getItem('jwt'), dispatch, true, function (err, res) {
        if (err) {
            dispatch(createMessage(err, 'danger'));
            isCallback(err, true, callback);
        } else {
            isCallback(res, false, callback);
        }
    });
}

export const fetchCreateCustomer = (planID,paymentMethodID,callback) => dispatch => {
    const body = {
        planID,
        paymentMethodID
    }
    FETCH('POST', '/payment/create-customer', body, localStorage.getItem('jwt'), dispatch, false, function (err, res) {
        if (err) {
            dispatch(createMessage(err, 'danger'));
            isCallback(err, true, callback);
        } else {
            dispatch(updateUser(res));
            isCallback(res, false, callback);
        }
    });
}

export function fetchGetBillingSession(callback) {
    const bearer = "Bearer " + localStorage.getItem("jwt");
    const requestOptions = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: bearer,
        },
    };

    fetch(API_URI + "/payment/create-session/billing", requestOptions)
        .then(res => {
            if(res.status == 401){
                window.location = "login";
            }else if(res.status>=400){
                return {error:"Bad request"};
            }else{
                return res.json();
            }
        })
        .then(data => {
            if (data.error) {
                callback(data.error);
            } else {
                callback(null,data.result);
            }
        });
}

export const fetchCheckoutSession = (stripePriceID,callback) => dispatch =>{
    let body = {
        priceID:stripePriceID
    }
    FETCH('POST','/payment/create-session/checkout',body,localStorage.getItem('jwt'),dispatch,true,function(err,res){
        if (err) {
            dispatch(createMessage(err, 'danger'));
            isCallback(err, true, callback);
        } else {
            isCallback(res, false, callback);
        }
    })
    
}