import { API_URI } from '../config/variables';
import { FETCH, isCallback } from '../config/helpers';
import { createMessage } from '../actions/alert';
import {FETCH_RESET_PASSWORD} from './types';
import {updateUser} from './user';

export const fetchSendActivation = (email,callback)=> dispatch => {
    let body = {
        email
    }
    
    FETCH('POST','/mailer/send-welcome',body,localStorage.getItem('jwt'),dispatch,true,function(err,res){
        if (err) {
            dispatch(createMessage(err, 'danger'));
            isCallback(err, true, callback);
        } else {
            dispatch(createMessage("Email has been sent to "+email,'success'));
            isCallback(res, false, callback);
        }
    })
}

export const fetchSendForgotPassword = (email,callback)=> dispatch => {
    let body = {
        email
    }
    
    FETCH('POST','/mailer/forgot-password',body,localStorage.getItem('jwt'),dispatch,true,function(err,res){
        if (err) {
            dispatch(createMessage(err, 'danger'));
            isCallback(err, true, callback);
        } else {
            dispatch(createMessage("Email has been sent to "+email,'success'));
            isCallback(res, false, callback);
        }
    })
}

export const fetchAuthenticateUser = (email,token,callback)=> dispatch => {
    
    FETCH('POST','/mailer/authenticate-user/'+email+"/"+token,null,null,dispatch,true,function(err,res){
        if (err) {
            dispatch(createMessage(err, 'danger'));
            isCallback(err, true, callback);
        } else {
            dispatch(updateUser(res));
            dispatch(createMessage("Email has been sent to "+email,'success'));
            isCallback(res, false, callback);
        }
    })
}
