
module.exports = {

    //remote live
    API_URI: "https://www.shopbanginempire.tv/api",
    CLIENT_URL:"https://www.shopbanginempire.tv",
    STRIPE_CLIENT_KEY: "pk_test_51IlMwQGBpcWbSf44tbKJCM8zgqiFPDqa8jIEMA3iFhWJeGWIF7IjuoEDMsLrq0obBQVjJ90MZ5QLsGHAq1Ps3sOs008Lt5VmtD"

    //remote testing
    // API_URI: "https://bangin-empire.herokuapp.com/api",
    // CLIENT_URL:"https://bangin-empire.herokuapp.com",
    // STRIPE_CLIENT_KEY: "pk_test_ttj4Nu9PA6N5g7X552cLoPqF"

    // local testing
    // API_URI: "http://localhost:4000/api",
    // CLIENT_URL:"http://localhost:3000",
    // STRIPE_CLIENT_KEY: "pk_test_ttj4Nu9PA6N5g7X552cLoPqF"
}