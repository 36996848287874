import React from "react";
import { connect } from 'react-redux';
import { getPath, formateDate} from '../../config/helpers';
import { createMessage } from '../../actions/alert';
import { toggleLoading } from '../../actions/loading';
import { addView } from '../../actions/media';
import { io } from 'socket.io-client';
import { API_URI } from '../../config/variables';

import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import Footer from "../../components/Footer/Footer.js";
import CommentSection from "../../components/Home/CommentSection";
import AdBanner from "../../components/Home/AdBanner";

class PostPage extends React.Component {
  state = {
    post: null,
    socket:null,
    clientMsg:""
  }
  constructor(props) {
    super(props);
  }
  _onPlayMedia = (e) => {

  }
  _onPauseMedia = (e) => {

  }
  _onSendBtn = (e) => {
    e.preventDefault();
    this._sendMessage();
  }
  _handleKeyDown = (e) => {
    if(e.key === 'Enter'){
      this._sendMessage();
    }
  }
  _changeSendInput = (e) => {
    let newState = this.state;
    newState.clientMsg = e.target.value;
    this.setState(newState);
  }
  _connectToChat = (mediaID, jwt, userID, callback) => {
    this.props.toggleLoading(true);
    let queryString = "mediaID=" + mediaID + "&userID=" + userID + "&jwt=" + jwt;
    let CHAT_URI = API_URI.slice(0,-4);
    let socket = io(CHAT_URI, { query: queryString });
    let newState = this.state;
    newState.socket = socket;
    this.setState(newState);

    socket.on("connect", () => {
      this.props.toggleLoading(false);

      socket.on('error', (err) => {
        this.props.createMessage(err,'danger');
      });

      this._getOldMessages();
      this._recieveMessages();

      callback(null, socket);
    });
  }
  _getOldMessages = () =>{
    let mediaID = this.state.post._id.toString();
    this.state.socket.on('RECIEVE_OLD_MESSAGES_ID='+mediaID,(oldMessages)=>{
      let newState = this.state;
      newState.post.comments = oldMessages;
      this.setState(newState);
    });
  }
  _recieveMessages = () =>{
    let mediaID = this.state.post._id.toString();
    this.state.socket.on('RECIEVE_MESSAGE_FROM_SERVER_ID='+mediaID,(newMessage)=>{
      let newState = this.state;
      newState.post.comments.push(newMessage);
      this.setState(newState);
    });
  }
  _sendMessage = () =>{
    if(this.state.clientMsg.length>0){
      let client = {
        message:this.state.clientMsg
      }
      let mediaID = this.state.post._id.toString();
      let newState = this.state;
      newState.clientMsg = "";
      this.setState(newState);
      this.state.socket.emit('SEND_MESSAGE_FROM_CLIENT_ID='+mediaID,client);
    }
  }
  _setState(name,value){
    let newState = this.state;
    newState[name] = value;
    this.setState(newState);
  }
  _getPostID = () => {
    let postID = getPath(window, "end");
    for (var i = 0; i < this.props.posts.length; ++i) {
      if (this.props.posts[i]._id.toString() == postID.toString()) {
        this._setState("post",this.props.posts[i]);
      }
    }
  }
  componentDidMount = () => {
    this._getPostID();
    this._connectToChat(this.state.post._id, localStorage.getItem('jwt'), this.props.user.data._id, (err, socket) => {
      if (err) {
        console.log(err);
        this.props.createMessage(err,'danger');
      } else {
        // this.props.createMessage('Successfully connected to chat.','success');
      }
    });
    this.props.addView(this.state.post._id,function(err,res){});
  }

  render() {


    return (
      <>
        <ExamplesNavbar />
        <div className="wrapper">
          <div style={{ marginTop: '7em' }}>
            <img
              alt="..."
              className="path"
              src={require("../../assets/img/path4.png").default}
            />
            <Container style={{ marginBottom: '1em' }}>
              {this.state.post ?
                <>
                  <Row>
                    <Col lg="8" md="12">
                      <video
                        onPlay={this._onPlayMedia}
                        onPause={this._onPauseMedia}
                        id={"player" + this.state.post._id}
                        preload="none"
                        controls
                        style={{ width: "100%", height: '380px' }}
                        poster={this.state.post.imgURL}
                        height="inherit"
                      >
                        <source src={this.state.post.url} type="audio/mp4" />
                      Your browser does not support the video element.
                      </video>
                    </Col>
                    <Col lg="4" md="12">
                      <CommentSection handleKeyDown={this._handleKeyDown.bind(this)}
                         changeSendInput={this._changeSendInput.bind(this)} sendMsg={this.state.clientMsg} sendMessage={this._sendMessage.bind(this)} 
                         comments={this.state.post.comments} onSendBtn={this._onSendBtn.bind(this)} changeSendInput={this._changeSendInput.bind(this)} />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="8" md="12">
                      <Card style={{ height: '215px' }} className="height-md-100">
                        <CardBody>
                          <h2 style={{ color: '#fff',marginBottom:'10px' }}>{this.state.post.name}</h2>
                          <p style={{fontSize:'10px'}}>{formateDate(this.state.post.dateCreated)}</p>
                          <div style={{ color: '#fff', paddingBottom: '3em' }}>
                            {this.state.post.description}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>

                : null}

            </Container>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  alert: state.alert,
  posts: state.posts
});
export default connect(mapStateToProps, {createMessage,toggleLoading,addView})(PostPage);