import { combineReducers } from 'redux';
import userReducer from './user';
import postsReducer from './posts';
import alertReducer from './alert';
import loadingReducer from './loading';

const appReducer = combineReducers({
    user:userReducer,
    posts:postsReducer,
    alert:alertReducer,
    loading:loadingReducer,
});

export const rootReducer = (state, action) => {
    if (action.type == 'RESET') {
      state = undefined
    }
    return appReducer(state, action)
  }