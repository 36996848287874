import React from 'react';

import AddMedia from '../../components/Admin/AddMedia';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardImg,
    CardTitle,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    FormGroup,
    Col,
  } from "reactstrap";

export default class AdminView extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return(
           <div className="container-fluid" style={{marginTop:'6em'}}>
               <div className="row">
                   <div className="col-lg-6 offset-lg-3">
                       <Card>
                           <CardBody>
                           <AddMedia/>
                           </CardBody>
                       </Card>
                   </div>
               </div>
           </div>
        )
    }
}