import React from "react";
import classnames from "classnames";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { emailValidator, passwordValidator} from "../../config/validators";
import { fetchLogin } from "../../actions/user"
import { resetRoot } from "../../actions/root";
import { createMessage } from "../../actions/alert"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  FormGroup,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import Footer from "../../components/Footer/Footer.js";

function LoginPage(props) {
  const [squares1to6, setSquares1to6] = React.useState("");
  const [squares7and8, setSquares7and8] = React.useState("");
  const [fullNameFocus, setFullNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);
  const [password] = React.useState("");
  const [passwordErrMsg] = React.useState("");
  const [email] = React.useState("");
  const [emailErrMsg] = React.useState("");
  const [state, setState] = React.useState({ password, passwordErrMsg, email, emailErrMsg});

  React.useEffect(() => {
    document.body.classList.toggle("register-page");
    document.documentElement.addEventListener("mousemove", followCursor);
    // Specify how to clean up after this effect:
    props.resetRoot();

    return function cleanup() {
      document.body.classList.toggle("register-page");
      document.documentElement.removeEventListener("mousemove", followCursor);
    };
  }, []);
  const followCursor = (event) => {
    let posX = event.clientX - window.innerWidth / 2;
    let posY = event.clientY - window.innerWidth / 6;
    setSquares1to6(
      "perspective(500px) rotateY(" +
      posX * 0.05 +
      "deg) rotateX(" +
      posY * -0.05 +
      "deg)"
    );
    setSquares7and8(
      "perspective(500px) rotateY(" +
      posX * 0.02 +
      "deg) rotateX(" +
      posY * -0.02 +
      "deg)"
    );
  };
  const _validation = () => {
    let newState = state;
    newState.emailErrMsg = emailValidator(state.email, true).errorMsg;
    newState.passwordErrMsg = passwordValidator(
      state.password,
      true
    ).errorMsg;
    setState({ ...state, newState });
  };
  const _onFormSubmit = (e) => {
    e.preventDefault();

    _validation();
    if (
      state.passwordErrMsg.length < 1 &&
      state.emailErrMsg.length < 1
    ) {
      props.fetchLogin(state.email,state.password,(err,result)=>{
        if (err) {
          console.log(err)
        } else {
          if (result.isAdmin) {
            window.location = "/admin-page";
          } else if(!result.isVerified){
            window.location = "/verify-page"
          }else if(!result.subscriptionRefID || result.subscriptionRefID.length<1){
            window.location = "plan"
          }else{
            window.location = "/home-page"
          }
        }
      });
    }else{
      let msg = state.passwordErrMsg + state.emailErrMsg;
      props.createMessage(msg,'danger',1000000);
    }
  }
  const _onChange = (e) => {
    e.preventDefault();
    setState({ ...state, [e.target.name]: e.target.value });
  };
  return (

    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content">
            <Container>
              <Row>
                <Col className="offset-lg-0 offset-md-3" lg="5" md="6">
                  <div
                    className="square square-7"
                    id="square7"
                    style={{ transform: squares7and8 }}
                  />
                  <div
                    className="square square-8"
                    id="square8"
                    style={{ transform: squares7and8 }}
                  />
                  <Card className="card-register">
                    <CardHeader>
                      <CardImg
                        alt="..."
                        src={require("../../assets/img/square2.png").default}
                      />
                      <CardTitle tag="h4" style={{fontSize:'4em',paddingLeft:'.75em'}}>Login</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form id="registerForm" onSubmit={_onFormSubmit} className="form">
                        <InputGroup
                          className={classnames({
                            "input-group-focus": emailFocus,
                            "has-danger": state.emailErrMsg
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="email"
                            name="email"
                            onLoad={(e) => { _onChange(e) }}
                            onChange={(e) => { _onChange(e) }}
                            onFocus={(e) => setEmailFocus(true)}
                            onBlur={(e) => setEmailFocus(false)}
                          />
                        </InputGroup>
                        <InputGroup
                          className={classnames({
                            "input-group-focus": passwordFocus,
                            "has-danger": state.passwordErrMsg
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-lock-circle" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Password"
                            type="password"
                            name="password"
                            onLoad={(e) => { _onChange(e) }}
                            onChange={(e) => { _onChange(e) }}
                            onFocus={(e) => setPasswordFocus(true)}
                            onBlur={(e) => setPasswordFocus(false)}
                          />
                        </InputGroup>
                        <FormGroup check className="text-left">
                      <span className="text-muted">Don't have an account?{" "}
                      <a
                        href="/sign-up-page"
                      >
                        Please Register
                      </a>
                      .
                      </span>
                      </FormGroup>
                      <FormGroup check className="text-left">
                      <span className="text-muted">Forgot password?{" "}
                      <a
                        href="/forgot-password-page"
                      >
                        Forgot Password
                      </a>
                      .
                      </span>
                      </FormGroup>
                        {/* <FormGroup check className="text-left">
  <Label check>
    <Input type="checkbox" />
    <span className="form-check-sign" />I agree to the{" "}
    <a
      href="#pablo"
      onClick={(e) => e.preventDefault()}
    >
      terms and conditions
    </a>
    .
  </Label>
</FormGroup> */}
                        <CardFooter>
                          <Button className="btn-round" type="submit" color="info" size="lg">
                            Get Started
</Button>
                        </CardFooter>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div className="register-bg" />
              <div
                className="square square-1"
                id="square1"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-2"
                id="square2"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-3"
                id="square3"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-4"
                id="square4"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-5"
                id="square5"
                style={{ transform: squares1to6 }}
              />
              <div
                className="square square-6"
                id="square6"
                style={{ transform: squares1to6 }}
              />
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
LoginPage.propTypes = {
  fetchLogin: PropTypes.func.isRequired,
  createMessage: PropTypes.func.isRequired,
  resetRoot: PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
  user: state.user,
  alert:state.alert
});
export default connect(mapStateToProps, { fetchLogin, createMessage, resetRoot })(LoginPage);
