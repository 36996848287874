import React from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    ListGroupItem,
    CardText,
    ListGroup,
    Form,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    CardImg,
    CardImgOverlay,
} from "reactstrap";

import Comment from './Comment';


export default function CommentSection(props) {
    const _scrollToLastItem = () => {
        var objDiv = document.getElementsByClassName("chat-body")[0];
        objDiv.scrollTop = objDiv.scrollHeight;
    }
    React.useEffect(() => {
        _scrollToLastItem();
    }); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour

    const list = () => {
        return props.comments.map((comment, index) => {
            return (
                <div id={"comment" + index}>
                    <Comment data={comment} key={index} />
                </div>
            )
        });
    }
    return (
        <Card style={{ height: '600px', position: 'absolute' }} className="position-md-relative height-md-400px">
            <CardTitle className="chat-title">Chat</CardTitle>
            <CardBody className="chat-body">
                {list()}
            </CardBody>
            <CardBody>
                <Row style={{ height: '100%' }}>
                    <Col xs="12">
                        <div id="send-input" class="input-group">
                            <input placeholder="Send Message..." onKeyDown={props.handleKeyDown} onChange={props.changeSendInput} value={props.sendMsg} type="text" class="form-control" />
                            <div class="input-group-append"><span class="input-group-text">
                                <i class="tim-icons icon-send" onClick={props.sendMessage} style={{ cursor: 'pointer' }}></i>
                            </span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}