import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./assets/scss/blk-design-system-react.scss?v=1.2.0";
import "./assets/demo/demo.css";
import "./assets/css/nucleo-icons.css";
import "./assets/css/custom.css";

import Index from "./views/Index.js";
import LandingPage from "./views/examples/LandingPage.js";
import HomePage from "./views/user/HomePage";
import ProfilePage from "./views/examples/ProfilePage.js";
import SignUpPage from "./views/shared/SignUpPage";
import LoginPage from "./views/shared/LoginPage";
import VerifyPage from "./views/user/VerifyPage";
import PlanPage from "./views/user/PlanView";
import CheckoutPage from "./views/user/CheckoutPage";
import AdminView from "./views/admin/AdminView";
import PostPage from "./views/user/PostPage";
import AccountVeiw from "./views/user/AccountView";
import ForgotPasswordPage from "./views/shared/ForgotPasswordPage";
import ResetPasswordPage from "./views/shared/ResetPasswordPage";

export default class App extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route
                        exact
                        path="/sign-up-page"
                        render={(props) => <SignUpPage {...props} />}
                    />
                    <Route
                        exact
                        path="/admin-page"
                        render={(props) => <AdminView {...props} />}
                    />
                     <Route
                        exact
                        path="/reset-password/:email/:token"
                        render={(props) => <ResetPasswordPage {...props} />}
                    />
                     <Route
                        exact
                        path="/account-page"
                        render={(props) => <AccountVeiw {...props} />}
                    />
                    <Route
                        exact
                        path="/forgot-password-page"
                        render={(props) => <ForgotPasswordPage {...props} />}
                    />
                    <Route
                        exact
                        path="/home-page"
                        render={(props) => <HomePage {...props} />}
                    />
                    <Route
                        exact
                        path="/post/:id"
                        render={(props) => <PostPage {...props} />}
                    />
                    <Route
                        exact
                        path="/checkout-page/:planID"
                        render={(props) => <CheckoutPage {...props} />}
                    />
                    <Route
                        exact
                        path="/login-page"
                        render={(props) => <LoginPage {...props} />}
                    />
                    <Route
                        exact
                        path="/plan"
                        render={(props) => <PlanPage {...props} />}
                    />

                    <Route exact path="/components" render={(props) => <Index {...props} />} />
                    <Route
                        exact
                        path="/landing-page"
                        render={(props) => <LandingPage {...props} />}
                    />
                    <Route
                        exact
                        path="/verify-page"
                        render={(props) => <VerifyPage {...props} />}
                    />
                    <Route
                        exact
                        path="/verify-page/:email/:token"
                        render={(props) => <VerifyPage {...props} />}
                    />
                    <Route
                        exact
                        path="/profile-page"
                        render={(props) => <ProfilePage {...props} />}
                    />
                    <Redirect from="*" to="/login-page" />
                </Switch>
            </BrowserRouter>
        )
    }
}