import React from 'react';
import { CircleToBlockLoading } from 'react-loadingg';
import {
    Card,
    CardBody
  } from "reactstrap";

export default function AlertBanner(props){
        return(
            <>
            {props.data?
            <div id="loading-container">
                <Card id="loading-card" className="blockquote blockquote-info">
                    <CardBody>
                        <CircleToBlockLoading color={"#1d8cf8"}/>
                        <h3 style={{marginTop:'8em',textAlign:'center'}}>Loading</h3>
                    </CardBody>
                </Card>
            </div>
            :null}
            </>
        )
    
}