//====================User================
export const FETCH_LOGIN = 'FETCH_LOGIN';
export const UPDATE_USER = 'UPDATE_USER';
export const FETCH_REGISTER = 'FETCH_REGISTER';
export const FETCH_RESET_PASSWORD = 'FETCH_RESET_PASSWORD';
//====================Alert===============
export const CREATE_MESSAGE = 'CREATE_MESSAGE';
//====================Posts===============
export const FETCH_POSTS = 'FETCH_POSTS';
//====================Loading=============
export const TOGGLE_LOADING = 'TOGGLE_LOADING';
//====================Root================
export const RESET = "RESET";
