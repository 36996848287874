import React from "react";
import { connect } from 'react-redux';
import { fetchSendForgotPassword } from '../../actions/mailer';
import { createMessage } from '../../actions/alert';
import { emailValidator } from "../../config/validators";
import {
    Button,
    Input,
    Label,
    FormText,
    Row,
    FormGroup,
    Col,
} from "reactstrap";
// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import Footer from "../../components/Footer/Footer.js";

function ForgotPasswordPage(props) {
    const [showBtn] = React.useState(false);
    const [email] = React.useState("");
    const [emailErrMsg] = React.useState("");
    const [state, setState] = React.useState({ showBtn, email, emailErrMsg });
    const _sendForgotPassword = (e) => {
        e.preventDefault();

        _validation();
        if (state.emailErrMsg.length < 1) {
            props.fetchSendForgotPassword(state.email,()=>{});
        }else{
            let msg = state.emailErrMsg;
            props.createMessage(msg,'danger',1000000);
        }
    }
    const _onChange = (e) => {
        e.preventDefault();
        setState({ ...state, [e.target.name]: e.target.value });
    };
    const _validation = () => {
        let newState = state;
        newState.emailErrMsg = emailValidator(state.email, true).errorMsg;
        setState({ ...state, newState });
    };
    React.useEffect(() => {
        document.body.classList.toggle("landing-page");
        // Specify how to clean up after this effect:
        return function cleanup() {
            document.body.classList.toggle("landing-page");
        };
    }, []);
    return (
        <>
            <ExamplesNavbar />
            <div className="wrapper">
                <div className="page-header">
                    <img
                        alt="..."
                        className="path"
                        src={require("../../assets/img/blob.png").default}
                    />
                    <img
                        alt="..."
                        className="path2"
                        src={require("../../assets/img/path2.png").default}
                    />
                    <img
                        alt="..."
                        className="shapes triangle"
                        src={require("../../assets/img/triunghiuri.png").default}
                    />
                    <img
                        alt="..."
                        className="shapes wave"
                        src={require("../../assets/img/waves.png").default}
                    />
                    <img
                        alt="..."
                        className="shapes squares"
                        src={require("../../assets/img/patrat.png").default}
                    />
                    <img
                        alt="..."
                        className="shapes circle"
                        src={require("../../assets/img/cercuri.png").default}
                    />
                    <div className="content-center">
                        <Row className="row-grid justify-content-between align-items-center text-left">
                            <Col lg="6" md="6">
                                <h1 className="text-white">
                                    We keep your account <br />
                                    <span className="text-white">secured</span>
                                </h1>

                                <p className="text-white mb-3">
                                    Enter your email below and click send.
                                </p>
                                <FormGroup>
                                    <Label for="exampleEmail">Email address</Label>
                                    <Input
                                        type="email"
                                        name="email"
                                        id="exampleEmail"
                                        placeholder="Enter email"
                                        onChange={_onChange}
                                        onLoad={_onChange}
                                        value={state.email}
                                    />
                                    <FormText color="muted">
                                        Let's get you back in the action!
                                    </FormText>
                                </FormGroup>
                                <Button onClick={_sendForgotPassword} className="btn btn-success">Send</Button>
                            </Col>
                            <Col lg="4" md="5">
                                <img
                                    alt="..."
                                    className="img-fluid"
                                    src={require("../../assets/img/mail.svg").default}
                                />
                            </Col>

                        </Row>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
    alert: state.alert
});
export default connect(mapStateToProps, { fetchSendForgotPassword,createMessage })(ForgotPasswordPage);