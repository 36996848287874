import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatPrice, getPath } from '../../config/helpers';
import { fetchGetAll } from '../../actions/plan'
import { updateUser } from '../../actions/user';
import { createMessage } from '../../actions/alert';
import {fetchIntent,fetchCreateCustomer } from '../../actions/payment';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "../../components/Navbars/ExamplesNavbar.js";
import Footer from "../../components/Footer/Footer.js";
import StripeForm from '../../components/checkout/StripeForm';

function CheckoutPage(props) {
  const [CLIENT_SECRET] = React.useState(null);
  const [amount] = React.useState(0);
  const [state, setState] = React.useState({ CLIENT_SECRET,amount });

 const  _fetchCreateCustomer = (paymentMethodID) =>{
    let planID = getPath(window, "end");
    props.fetchCreateCustomer(planID,paymentMethodID,(err,res)=>{
      if(err){
        console.log(err);
      }else{
        setTimeout(()=>{
          window.location = "/home-page";
        },2000);
      }
    });
  }
const _createFailMessage = (msg) =>{
  props.createMessage(msg,'danger');
} 
const _createSuccessMessage = (msg) =>{
  props.createMessage(msg,'success');
} 
  React.useEffect(() => {
    let id = getPath(window, "end");
    props.fetchIntent(id, (err, result) => {
      if (err) {
        console.log(err);
      } else {
        setState({...state,CLIENT_SECRET:result.client_secret,amount:result.amount});
      }
    });
    document.body.classList.toggle("landing-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("landing-page");
    };
  }, []);
  
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <div style={{ marginTop: '5em' }}>
          <Container>
            <Row style={{marginRight:'0px',marginLeft:'0px'}}>
              <Col xs="12">
                <Card className="card-plain">
                  <CardHeader>
                    <h1 className="profile-title text-left">Checkout</h1>
  <h5 className="text-on-back">${formatPrice(state.amount)}</h5>
                  </CardHeader>
                  <CardBody>
                  {state.CLIENT_SECRET ? (
                <StripeForm
                  CLIENT_SECRET={state.CLIENT_SECRET}
                  createCustomer={_fetchCreateCustomer}
                  successMsg = {_createSuccessMessage}
                  failMsg = {_createFailMessage}
                  total={formatPrice(state.amount)}
                />
              ) : null}
                  </CardBody>
                </Card>
              </Col>
              <Col>
              <img
            alt="..."
            className="path"
            src={require("../../assets/img/blob.png").default}
          />
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}
CheckoutPage.propTypes = {
  fetchGetAll: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  fetchIntent: PropTypes.func.isRequired
};
const mapStateToProps = (state) => ({
  user: state.user,
  alert: state.alert
});
export default connect(mapStateToProps, { fetchGetAll, updateUser, fetchIntent,createMessage,fetchCreateCustomer })(CheckoutPage);
