import { API_URI, CLIENT_URL } from './variables';
import { toggleLoading } from '../actions/loading';
import { createMessage } from '../actions/alert';
import moment from 'moment';

export function formatPrice(inCents) {
    let price = inCents / 100;
    // price = price.toFixed(2);
    return price;
}

export function formateDate(date) {
    if (date) {

        let d = new Date(date);
        d = d.getMonth() + 1 + '/' + (d.getDate()) + '/' + d.getFullYear();

        if (d.toString().includes('T')) {
            d = d.toString().split('T')[0];
        }
        return d;


    } else {
        return null;
    }
}

export function formateDateAndTime(dateObj) {
    // var datetime = "LastSync: " + formateDate(date) + " @ " + new Date(date).timeNow().toString();
    let date = moment(new Date(dateObj)).fromNow();
    return date
}

export function getPath(window, index) {
    let arr = window.location.pathname.split('/');
    if (!index) {
        return arr;
    } else if (index == "end") {
        return arr[arr.length - 1];
    } else {
        return arr[index];
    }
}

export function redirect(path) {
    window.location.href = CLIENT_URL + path;
}
export function verifyUser(user) {
    let jwt = localStorage.getItem('jwt');
    if (!user || !user.data && window.location.toString() != (CLIENT_URL + '/login') && jwt) {
        window.location.href = CLIENT_URL + "/login";
    }
}
export function isCallback(value, isError, callback) {
    if (callback && isError) {
        callback(value)
    } else if (callback) {
        callback(null, value)
    }
}
export function FETCH(type, url, body, jwt, dispatch, isLoading, done) {
    if (isLoading) {
        dispatch(toggleLoading(true));
    }
    let fetchObj = {
        method: type,
        headers: {
            'content-type': 'application/json'
        }
    }
    if (body && type == "POST" || type == "PATCH") {
        fetchObj.body = JSON.stringify(body);
    }
    let uri = API_URI + url;
    if (jwt) {
        fetchObj.headers.Authorization = "Bearer " + jwt;
    }

    let isFetched = false;
    if (isLoading) {
        dispatch(createMessage("", ""));
    }
    fetch(uri, fetchObj)
        .then(res => {
            if (res.ok) {
                return (res.json())
            } else {
                return ({ error: "Restricted access please login/register.",redirectURL:'login-page'})
            }
        })
        .then(res => {
            isFetched = true;
            if (isLoading) {
                dispatch(toggleLoading(false));
            }
            if (res.redirectURL) {
                dispatch(createMessage(res.error, 'danger'));
                redirect('/' + res.redirectURL);
            } else if (res.error) {
                dispatch(createMessage(res.error, 'danger'));
            } else if (done && res && res.error) {
                done(res.error.toString());
            } else if (done && res) {
                done(null, res.result);
            } else {
                done(null, null)
            }
        });
    setTimeout(() => {
        if (!isFetched) {
            dispatch(toggleLoading(false));
            dispatch(createMessage("Unable to connect to server", 'danger'));
        }
    }, 15000);
}